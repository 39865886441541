/* A potentially shared website could appear in an iframe in the cowebsite space. */

#cowebsite {
  position: fixed;
  transition: transform 0.5s;
  background-color: white;
  
  &.loading {
    background-color: gray;
  }

  main {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  
  aside {
    background: gray;
    align-items: center;
    display: flex;

    img {
      margin: 3px;
      pointer-events: none;
      height: 20px;
    }
  }

  .top-right-btn{
    position: absolute;
    background: none;
    border: none;
    cursor: url('/resources/logos/cursor_pointer.png'), pointer;

    img {
      height: 25px;
      padding: 4px;
      border-radius: 3px;
    }

    img:hover {
      background-color: rgba(0,0,0,0.4);
    }
  }
}

@media (min-aspect-ratio: 1/1) {
  #cowebsite {
    right: 0;
    top: 0;
    width: 50%;
    height: 100vh;
    display: flex;

    &.loading {
      transform: translateX(90%);
    }
    &.hidden {
      transform: translateX(100%);
    }

    main {
      width: 100%;
    }


    aside {
      width: 30px;
      cursor: ew-resize;

      img {
        cursor: ew-resize;
        transform: rotate(90deg);
      }
    }

    .top-right-btn{
      left: -6px;
      &#cowebsite-close {
        top: 0px;
      }
      &#cowebsite-fullscreen {
        top: 25px;
      }
    }
  }
}
@media (max-aspect-ratio: 1/1) {


  #cowebsite {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;

    &.loading {
      transform: translateY(90%);
    }
    &.hidden {
      transform: translateY(100%);
    }

    main {
      height: 100%;
    }


    aside {
      height: 30px;
      cursor: ns-resize;
      flex-direction: column;
      
      img {
        cursor: ns-resize;
      }
    }

    .top-right-btn {
      &#cowebsite-close {
        right: 0px;
      }
      &#cowebsite-fullscreen {
        right: 25px;
      }
    }
  }
}